import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Services/ServicesHome_3";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockFour from "../components/block/BlockFour";
import BlockThree from "../components/block/BlockThree";
import BlockOne from "../components/block/BlockOne";
import VideoPromocional from "../components/global/VideoPromocional";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">

        <Modal />

        <HeroSection />

        <div className="relative">

          <BlockOne
            title={rpdata?.dbSlogan?.[1]?.slogan}
            text={rpdata?.dbHome?.[0].text}
            image={rpdata?.stock?.[19]}
            listsServices
          />

          <div className="absolute w-full top-10">
            <div className="flex justify-end ">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/stock%2F10%20years.png?alt=media&token=eadb26b2-9319-4f82-b576-84ca37b0571b"
                alt="not found"
                className="w-[40%] md:w-[20%]"
              />
            </div>
          </div>
        </div>


        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={rpdata?.gallery?.[1]}
            />
            : null
        }

        <Directories />

        <BlockFour
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image1={rpdata?.stock?.[21]}
          image2={rpdata?.stock?.[7]}
          image3={rpdata?.stock?.[5]}
        />

        <CounterUp image={rpdata?.stock?.[26]} />

        <BlockThree
          title={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.stock?.[24]}
          listsAbout={true}
        />

        <ServicesHome />

        {/* Paleta de Colores */}

        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.stock?.[28]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>

        <Map />

      </div>
    </BaseLayout>
  );
}

export default Home;
